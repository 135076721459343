import React from 'react';
import NextLink from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Layout } from '../src/components';
import { errorConstants } from '../src/constants';

import './_error.scss';

function Error({ statusCode }) {
  const errorObj = errorConstants[statusCode?.toString()] || errorConstants['500'];

  return (
    <div className="error-content">
      <div className="error-code">{statusCode}</div>
      <div className="error-message-primary">{errorObj.title}</div>
      <div className="error-message-secondary">{errorObj.message}</div>
      <div className="action-buttons">
        <NextLink href="/">
          <a href="/" className="button button--primary">
            <FontAwesomeIcon icon={faHome} />
            &nbsp; GO TO HOMEPAGE
          </a>
        </NextLink>

        <NextLink href="/">
          <a
            href="https://help.smata.com/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
            className="button button--line-primary"
          >
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp; CONTACT SUPPORT
          </a>
        </NextLink>
      </div>
    </div>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

Error.getLayout = page => <Layout headerClassName="mw-100">{page}</Layout>;

export default Error;
